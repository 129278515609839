import React from "react"
import styled from "styled-components"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BreakpointUp from "../components/Media/BreakpointUp"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import {
  Section,
  SectionPageTitle,
  SectionTitle,
  SectionLead,
  MarkdownContent,
  BreadCrumb,
} from "../components/Section"
import PersonalInformationBecomeInstallerForm from "../components/PersonalInformationBecomeInstallerForm"
import ListArrow from "../images/BulletIcon.svg"
import CallNow from "../components/CallNow"

const SectionTopHeader = styled.div`
  position: relative;
`
const LeaveBgImage = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  opacity: 0.1;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const SectionOverlayBg = styled.div`
  height: 100%;
  width: 30%;
  position: absolute;
  top: 0px;
  right: 0;

  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  .gatsby-image-wrapper {
    height: 100%;
    opacity:0.3;
    ${BreakpointUp.sm` 
      opacity:1;
    `}
  }
`
const SectionOverlay = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionOverlayContent = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 950px;
  width: 100%;
  & p {
    margin-bottom: 50px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
  }
  & strong {
    display: block;
    color: #999;
    font-weight: 700;
    margin-bottom: 15px;
  }
  & ul {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
    & li {
      padding-left: 20px;
      vertical-align: text-top;
      margin: 0;
      line-height: 26px;
      + li {
        margin-top: 15px;
      }
      &:before {
        content: url(${ListArrow});
        display: inline-block;
        margin-left: -20px;
        width: 20px;
        vertical-align: top;
      }
    }
  }
`

const BecomeInstaller = ({ data, location }) => {
  const pageData = data.contentfulBecomeAnInstallerPage
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <BreadCrumb className="white">
        <div className="container mw-950">
          <Link to="/">Home / </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="220px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#0B619B"
        className="circle-right top-0 sm"
      >
        <div className="container mw-950">
          <SectionTopHeader>
            <SectionPageTitle textAlign="left" color="#fff" mb="0">
              {pageData.heroTitle}
            </SectionPageTitle>
          </SectionTopHeader>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container mw-950">
          <MarkdownContent>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.description.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
        </div>
      </Section>
      <Section pt="140px" pb="140px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <SectionOverlayBg>
          <GatsbyImage
            image={pageData.howToSection.backgroundImage.gatsbyImageData}
            alt={pageData.howToSection.backgroundImage.alt}
          />
        </SectionOverlayBg>
        <div className="container">
          <SectionOverlay>
            <SectionOverlayContent>
              <h2>{pageData.howToSection.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.howToSection.description.childMarkdownRemark.html,
                }}
              />
            </SectionOverlayContent>
          </SectionOverlay>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container mw-950">
          <MarkdownContent>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
        </div>
      </Section>
      <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-right md"
      >
        <LeaveBgImage>
          <StaticImage src="../images/leave-review.jpg" alt="leave-review" />
        </LeaveBgImage>
        <div className="container mw-950">
          <SectionTitle mb="20px">{pageData.formTitle}</SectionTitle>
          <SectionLead mb="60px" maxWidth="1100px">
            {pageData.formDescription}
          </SectionLead>
          <PersonalInformationBecomeInstallerForm states={data.allContentfulState.edges} location={location} />
        </div>
      </Section>
      <CallNow />
    </Layout>
  )
}

export default BecomeInstaller

export const pageQuery = graphql`
  query BecomeInstallerQuery {
    contentfulBecomeAnInstallerPage {
      pageName
      metaTitle
      metaDescription {
        metaDescription
      }
      heroTitle
      description {
        childMarkdownRemark {
          html
        }
      }
      howToSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          title
          gatsbyImageData
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      formTitle
      formDescription
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
